import { POI_MANUFACTURER_KEYWORDS, POI_RENTAL_TYPE_KEYWORDS, POI_RENTAL_TYPE_KEYWORDS_EN_TO_FR, POI_RENTAL_TYPE_KEYWORDS_FR_TO_EN } from '~/constants/keywords'
import type { SupportedLocales } from '~/types'

export default defineNuxtRouteMiddleware((to) => {
  const keywordTranslated: Record<SupportedLocales, Record<string, string>> = {
    fr: POI_RENTAL_TYPE_KEYWORDS_EN_TO_FR,
    en: POI_RENTAL_TYPE_KEYWORDS_FR_TO_EN,
  }

  function getTranslatedKeyword(keyword: string, locale: string) {
    return keywordTranslated?.[locale as SupportedLocales]?.[keyword]
  }

  const { $i18n } = useNuxtApp()

  const localePath = useLocalePath()
  const getRouteBaseName = useRouteBaseName()

  const keyword = Array.isArray(to.params?.keyword) ? to.params?.keyword.join(' ') : to.params?.keyword

  if (keyword) {
    /**
     * If the keyword is translated, redirect to the translated keyword page
     */
    const translatedKeyword = getTranslatedKeyword(keyword, $i18n.locale.value)
    if (translatedKeyword && translatedKeyword !== keyword) {
      return navigateTo(localePath({ name: getRouteBaseName(to), params: { keyword: translatedKeyword, destinationPath: to.params?.destinationPath, campgroundSlug: to.params?.campgroundSlug } }), {
        redirectCode: 302,
      })
    }

    /**
     * If the keyword doesn't match supported keywords, remove keyword from the URL
     */
    if (![...POI_RENTAL_TYPE_KEYWORDS, ...Object.keys(POI_MANUFACTURER_KEYWORDS)].includes(keyword)) {
      return createError({
        statusCode: 404,
        statusMessage: 'Keyword not found',
      })
    }
  }
})
